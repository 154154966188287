<template>
  <van-overlay :show="visible" @click="claseV" :lock-scroll="true">
    <div class="wrapper" v-if="transmitData !== null">
      <div class="block">
        <div class="theight position">{{transmittitle}}分析报告</div>
        <div class="chart-wrap">
          <v-chart :options="options" />
        </div>
        <div class="we-bg-color text-align-c table fontcolor">
          <van-row gutter="4">
          <van-col span="6">项目</van-col>
          <van-col span="6">数值</van-col>
          <van-col span="6">参考范围</van-col>
          <van-col span="6">提示</van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">脉位</van-col>
          <van-col span="6">{{transmitData.maiwei}}</van-col>
          <van-col span="6">{{transmitData.maiweiConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.maiweiShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.maiweiShow== 2">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">脉率</van-col>
          <van-col span="6">{{transmitData.mailv}}</van-col>
          <van-col span="6">{{transmitData.mailvConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.mailvShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.mailvShow== 2 ">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">节律</van-col>
          <van-col span="6">{{transmitData.jielv}}</van-col>
          <van-col span="6">{{transmitData.jielvConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.jielvShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.jielvShow== 2 ">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">脉力</van-col>
          <van-col span="6">{{transmitData.maili}}</van-col>
          <van-col span="6">{{transmitData.mailiConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.mailiShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.mailiShow== 2 ">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">紧张度</van-col>
          <van-col span="6">{{transmitData.jinzhangdu}}</van-col>
          <van-col span="6">{{transmitData.jinzhangduConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.jinzhangduShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.jinzhangduShow== 2 ">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        <van-row gutter="4">
          <van-col span="6">流利度</van-col>
          <van-col span="6">{{transmitData.liulidu}}</van-col>
          <van-col span="6">{{transmitData.liuliduConfig}}</van-col>
          <van-col span="6">
            <span v-if="transmitData.liuliduShow == 1">
                <van-image width="15" :src="require('@assets/images/rise.png')" />
              </span>
              <span v-if="transmitData.liuliduShow== 2 ">
                <van-image width="15" :src="require('@assets/images/down.png')" />
              </span>
          </van-col>
        </van-row>
        
          
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { NavBar, Col, Row, Cell, Field, Grid, GridItem, Overlay ,Image as VanImage,} from "vant";
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/markLine";

export default {
  name: "reportitem",
  props:{
    transmitData:{
      type:Object,
      default:{}
    },
    transmittitle:{
      type:String,
      default:null
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Col.name]: Col,
    [Row.name]: Row,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Overlay.name]: Overlay,
    "v-chart": ECharts,
    [VanImage.name]: VanImage,

    // [DatePicker.name]: DatePicker,
    // [Input.TextArea.name]: Input.TextArea,
    // [Select.name]: Select,
    // [Select.Option.name]: Select.Option,
  },
  data() {
    //  this.lastFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 800);
    return {
      visible: false,
      options: {
        xAxis: {
          name: "t(s)",
          show: true,
          axisLine: {
            lineStyle: {
              color: "rgba(48, 46, 46, 1)",
            },
            symbol: ["none", "arrow"],
            symbolSize: [5, 5],
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          name: "h(g)",
          show: true,
          axisLine: {
            lineStyle: {
              color: "rgba(48, 46, 46, 1)",
            },
            symbol: ["none", "arrow"],
            symbolSize: [5, 5],
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        series: [
          {
            data: [
              [0, 0],
              [60, 1566],
              [120, 600],
              [150, 700],
              [200, 800],
              [400, 0],
            ],
            type: "line",
            lineStyle: {
              color: "rgba(158, 160, 214, 1)",
            },
            itemStyle: {
              color: "rgba(158, 160, 214, 1)",
              opacity: 0,
            },
            smooth: true,
            markLine: {
              data: [
                [
                  {
                    name: "h1",
                    coord: [60, 0],
                  },
                  {
                    coord: [60, 1556],
                  },
                ],
                [
                  {
                    name: "h2",
                    coord: [120, 0],
                  },
                  {
                    coord: [120, 600],
                  },
                ],
                [
                  {
                    name: "h3",
                    coord: [150, 0],
                  },
                  {
                    coord: [150, 700],
                  },
                ],
                [
                  {
                    name: "h4",
                    coord: [200, 0],
                  },
                  {
                    coord: [200, 800],
                  },
                ],
                [
                  {
                    name: "",
                    coord: [150, 700],
                  },
                  {
                    coord: [200, 700],
                  },
                ],
              ],
              lineStyle: {
                color: "rgba(26, 163, 153, 1)",
                type: "solid",
              },
              symbol: "arrow",
              symbolSize: 5,
              label: {
                show: true,
                position: "middle",
              },
            },
          },
        ],
      },
    };
  },
  
  computed: {},
  methods: {
    show() {
      this.visible = true;
    },
    claseV() {
      this.visible = false;
    },
  },
  watch: {
     transmitData: {
       handler(val) {
        let { optParv, optParh } = val;
        let data = [[0, 0]];
        val.optParv.map((item, index) => {
          data.push([val.optParh[index], item]);
        });
        data.push([val.optPulseLen, 0]);
        let findMin = Math.min.apply(null, [data[4][1], data[5][1]]);
        let markLine = {
          data: [
            [
              {
                name: "h1",
                coord: [data[1][0], 0],
              },
              {
                coord: [data[1][0], data[1][1]],
              },
            ],
            [
              {
                name: "h2",
                coord: [data[2][0], 0],
              },
              {
                coord: [data[2][0], data[2][1]],
              },
            ],
            [
              {
                name: "h3",
                coord: [data[3][0], 0],
              },
              {
                coord: [data[3][0], data[3][1]],
              },
            ],
            [
              {
                name: "h4",
                coord: [data[4][0], 0],
              },
              {
                coord: [data[4][0], data[4][1]],
              },
            ],
            [
              {
                name: "h5",
                coord: [data[5][0], data[4][1]],
              },
              {
                coord: [data[5][0], data[5][1]],
              },
            ],
            [
              {
                name: "",
                coord: [data[4][0], findMin],
              },
              {
                coord: [data[5][0], findMin],
              },
            ],
          ],
          lineStyle: {
            color: "rgba(26, 163, 153, 1)",
            type: "solid",
          },
          symbol: "arrow",
          symbolSize: 5,
          label: {
            show: true,
            position: "middle",
          },
        };
        this.options.series[0].data = data;
        this.options.series[0].markLine = markLine;
      },
       immediate: true
    },
    
  }
};
</script>
<style lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  // height: 420px;
  // padding: 15px;
  background-color: #fff;
}
.position{
  position: absolute;
}
.chart-wrap{
  width: 100%;
  height: 250px;
}
.echarts {
  width: 400px;
  height: 250px;
}
.van-grid-item__content{
  padding:  0.2rem;
}
.table {
  margin: 20px 10px 30px;
  .van-row {
    // margin-bottom: 4px;
    .van-col {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-clip: content-box;
    }
    .van-col--18 {
      // padding: 5px;
      text-align: left;
      line-height: 26px;
      height: auto;
      // background-clip: border-box;
    }
  }
  .van-row:nth-child(odd) {
    background-color: #fff;
    .van-col {
      background-color: #baf7c4;
      color: #3f3f3f;
    }
  }
  .van-row:nth-child(even) {
    .van-col {
      background-color: #deffe3;
      color: #3f3f3f;
    }
  }
  .van-row:nth-child(1) {
    .van-col {
      color: #009018;
    }
  }
}

</style>